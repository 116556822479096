import React from 'react'

const AddNewNursery = () => {
    return (
        <div className="col-xl-8">
            <div className="card mb-4">
                <div className="card-header">Nursery Details </div>
                <div className="card-body">
                    <form>
                        
                        <div className="row gx-3 mb-3">
                            <div className="col-md-12">
                                <label className="small mb-1" for="inputUsername">Nursery Name</label>
                                <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo Nursery" />
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" for="inputOrgName">Nursery Address</label>
                                <input className="form-control" id="inputOrgName" type="text" placeholder="Enter your organization name" value="Start Bootstrap" />
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" for="inputLocation">City</label>
                                <input className="form-control" id="inputLocation" type="text" placeholder="Enter your location" value="San Francisco, CA" />
                            </div>
                        </div>

                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" for="inputEmailAddress">Nursery Email address</label>
                                <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="name@example.com" />
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" for="inputEmailAddress">Nursery Area Pin Code</label>
                                <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="101585" />
                            </div>
                        </div>


                        <div className="row gx-3 mb-3">

                            <div className="col-md-6">
                                <label className="small mb-1" for="nurseryPhone">Nursery Phone number</label>
                                <input className="form-control" id="nurseryPhone" type="tel" placeholder="Enter your phone number" value="555-123-4567" />
                            </div>

                            <div className="col-md-6">
                                <label className="small mb-1" for="nurseryDate">Nursery Start Date</label>
                                <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
                            </div>
                        </div>

                        <div className="row gx-3 mb-3">

                            <div className="col-md-12">
                                <label className="small mb-1" for="nurseryPhone">Nursery Google Map Location</label>
                                <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="https://maps.app.goo.gl/HR9VWb8WSVRW5z3f8" />
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">

                            <div className="col-md-12">
                                <label className="small mb-1" for="nurseryPhone">Nursery Size </label>
                                <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="small" />
                            </div>
                        </div>
                        <button className="btn btn-success" type="button">Save changes</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddNewNursery
