import React from 'react'
import logo from '../../Assets/plantifo_Logo.png'
import { Link } from 'react-router-dom'


const Register = () => {
    return (
        <>
            <section className="bg-success py-3 py-md-5 py-xl-8">
                <div className="container">
                    <div className="row gy-4 align-items-center">
                        <div className="col-12 col-md-6 col-xl-7">
                            <div className="d-flex justify-content-center text-bg-success">
                                <div className="col-12 col-xl-9">
                                    <img className="img-fluid rounded mb-4" loading="lazy" src={logo} width="245" height="80" alt="BootstrapBrain Logo" />
                                    <hr className="border-primary-subtle mb-4" />
                                    <h2 className="h1 mb-4">We make digital products that drive you to stand out.</h2>
                                    <p className="lead mb-5">We write words, take photos, make videos, and interact with artificial intelligence.</p>
                                    <div className="text-endx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-5">
                            <div className="card border-0 rounded-4">
                                <div className="card-body p-3 p-md-4 p-xl-5">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-4">
                                                <h2 className="h3">Registration</h2>
                                                <h3 className="fs-6 fw-normal text-secondary m-0">Enter your details to register</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <form action="#!" >
                                        <div className="row gy-3 overflow-hidden">
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="firstName" id="firstName" placeholder="First Name" required autocomplete="off" />
                                                    <label for="firstName" className="form-label">First Name</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="lastName" id="lastName" placeholder="First Name" required autocomplete="off" />
                                                    <label for="lastName" className="form-label">Last Name</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required autocomplete="off" />
                                                    <label for="email" className="form-label">Owner Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" name="nurseryEmail" id="nurseryEmail" placeholder="name@example.com" required autocomplete="off" />
                                                    <label for="nurseryEmail" className="form-label">Nursery Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="tel" className="form-control" name="nurseryPhone" id="nurseryPhone" placeholder="0112- 157-487" required autocomplete="off" />
                                                    <label for="nurseryPhone" className="form-label">Nursery Phone Number</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="tel" className="form-control" name="nurseryOwnerPhone" id="nurseryOwnerPhone" placeholder="0112- 157-487" required autocomplete="off" />
                                                    <label for="nurseryOwnerPhone" className="form-label">Nursery Owner Phone Number</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="nurseryName" id="nurseryName" placeholder="Nursery Name" required autocomplete="off" />
                                                    <label for="nurseryName" className="form-label">Nursery Name</label>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" name="nurseryAddress" id="nurseryAddress" placeholder="Nursery Address" required autocomplete="off" />
                                                    <label for="nurseryAddress" className="form-label">Nuersery Address</label>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-floating mb-3">
                                                    <input type="number" className="form-control" name="nurseryPinCode" id="nurseryPinCode" placeholder="Pin Code" required autocomplete="off" />
                                                    <label for="nurseryPinCode" className="form-label">Area Pin Code</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="file" className="form-control" name="nurseryLogo" id="nurseryLogo" required autocomplete="off" />
                                                    <label for="nurseryLogo" className="form-label">Upload Nursery Logo</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="month" className="form-control" name="nurseryDate" id="nurseryDate" required autocomplete="off" />
                                                    <label for="nurseryDate" className="form-label">Nursery Start Date</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="password" className="form-control" name="nurseryDate" id="nurseryDate" required autocomplete="off" />
                                                    <label for="nurseryDate" className="form-label">password</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating mb-3">
                                                    <input type="password" className="form-control" name="nurseryDate" id="nurseryDate" required autocomplete="off" />
                                                    <label for="nurseryDate" className="form-label">Conform password</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="iAgree" id="iAgree" required autocomplete="off" />
                                                    <label className="form-check-label text-secondary" for="iAgree">
                                                        I agree to the <a href="#!" className="link-primary text-decoration-none">terms and conditions</a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button className="btn btn-success btn-lg" type="submit">Register Now</button>
                                                </div>
                                            </div>
                                            {/* <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-end mt-4">
                                                        <p class="m-0 text-secondary text-center">Already have an account? <Link to='/login' class="link-primary text-decoration-none">Sign in</Link></p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Register
