import React, { useState } from 'react'
import avtar from '../../Assets/avtar.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../Assets/logo.png'


const Test = () => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (e, dropdown) => {
        e.preventDefault();
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };
    const [sidebarVisible, setSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState);
    };

    return (
        <>
         <div className="app-wrapper">
         <nav className="app-header navbar navbar-expand bg-body bg-dark" data-bs-theme="dark">
                <div className="container-fluid">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-lte-toggle="sidebar"
                                onClick={toggleSidebar}
                                href="#"
                                role="button"
                            >
                                <i className="bi bi-list"></i>
                            </a>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <a href="/" className="nav-link">Home</a>
                        </li>

                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown user-menu">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                <img width={35}
                                    src={avtar}
                                    className="user-image rounded-circle shadow"
                                    alt="User Image"
                                />
                                <span className="d-none d-md-inline">Alexander Pierce</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-end">

                                <li className="user-header text-bg-dark">
                                    <img
                                        src={avtar} width={35}
                                        className="rounded-circle shadow"
                                        alt="User Image"
                                    />

                                    <p>
                                        Alexander Pierce - Web Developer
                                        <small>Member since Nov. 2023</small>
                                    </p>
                                </li>

                                <li className="user-body">
                                </li>
                                <li className="user-footer">
                                    <a href="#" className="btn btn-default btn-flat">Profile</a>
                                    <a href="#" className="btn btn-default btn-flat float-end">Sign out</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* Sidebar */}
            <aside className={`app-sidebar bg-body-secondary shadow ${sidebarVisible ? 'd-block' : 'd-none'}`} data-bs-theme="dark">


                <div className="sidebar-brand">  <a href="#" className="brand-link">  <img src={logo} alt="Plantifo" className="brand-image opacity-75 shadow" />  <span className="brand-text fw-light">Plantifo</span>  </a>  </div>
                <div className="sidebar-wrapper">
                    <nav className="mt-2">
                        <ul className="nav sidebar-menu flex-column" data-lte-toggle="treeview" role="menu" data-accordion="false">
                            
                            <li className="nav-item">
                                <a onClick={(e) => toggleDropdown(e,'dashboard')} className="nav-link "> <i className="nav-icon bi bi-speedometer"></i>
                                    <p>
                                        Dashboard
                                        <i className="nav-arrow "></i>
                                    </p>
                                </a>

                            </li>
                            <li className="nav-header">Nursery</li>

                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'Nursery')} className="nav-link active"> <i className="nav-icon bi bi-tree-fill"></i>
                                    <p>
                                        Nursery
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'Nursery' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#!" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Nursery</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="./widgets/info-box.html" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Nursery</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="./widgets/info-box.html" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Pending Nursery</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="./widgets/info-box.html" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Regected Nursery</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>



                            <li className="nav-header">Support</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'nsupport')} className="nav-link active"> <i className="nav-icon bi bi-ticket-fill"></i>
                                    <p>
                                        Nursery Support
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'nsupport' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Support Tickets</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Support Ticket</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'usupport')} className="nav-link active"> <i className="nav-icon bi bi-ticket-fill"></i>
                                    <p>
                                        User Support
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'usupport' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Support Tickets</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Support Tickets</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>


                            <li className="nav-header">Announcement</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'Announcement')} className="nav-link active"> <i className="nav-icon bi bi-megaphone-fill"></i>
                                    <p>
                                        Nursery Announcement
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'Announcement' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Announcement</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Announcement</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'uAnnouncement')} className="nav-link active"> <i className="nav-icon bi bi-megaphone-fill"></i>
                                    <p>
                                        User Announcement
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'uAnnouncement' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Announcement</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Announcement</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>





                            <li className="nav-header">FAQ</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'FAQ')} className="nav-link active"> <i className="nav-icon bi bi-question-circle-fill"></i>
                                    <p>
                                        Nursery FAQ
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'FAQ' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All FAQ</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New FAQ</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'uFAQ')} className="nav-link active"> <i className="nav-icon bi bi-question-circle-fill"></i>
                                    <p>
                                        User FAQ
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'uFAQ' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All FAQ</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New FAQ</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-header"> Feedback</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'nfb')} className="nav-link active"> <i className="nav-icon bi bi-cup-hot-fill"></i>
                                    <p>
                                        Nursery Feedback
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'nfb' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Feedback</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New FeedBack</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Unpublish Feedbak</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Rejected Feedback</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>

                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'ufb')} className="nav-link active"> <i className="nav-icon bi bi-cup-hot-fill"></i>
                                    <p>
                                        User Feedback
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'ufb' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Feedback</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New FeedBack</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Unpublish Feedbak</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Rejected Feedback</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>


                            <li className="nav-header"> Rating</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'nrfb')} className="nav-link active"> <i className="nav-icon bi bi-star-half"></i>
                                    <p>
                                        Nursery Rating
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'nrfb' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Unpublish Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Rejected Rating</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>

                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'rfb')} className="nav-link active"> <i className="nav-icon bi bi-star-half"></i>
                                    <p>
                                        User Rating
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'rfb' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Add New Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Unpublish Rating</p>
                                        </a> </li>
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>Rejected Rating</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-header">Admin</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'Admin')} className="nav-link active"> <i className="nav-icon bi bi-person-fill-check"></i>
                                    <p>
                                        Admin
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'Admin' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>View All Admins</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>
                            <li className="nav-header">Setting</li>
                            <li className="nav-item menu-open">
                                <a href="#" onClick={(e) => toggleDropdown(e,'bsetting')} className="nav-link active"> <i className="nav-icon bi bi bi-gear-fill"></i>
                                    <p>
                                        Basic Setting
                                        <i className="nav-arrow bi bi-chevron-right"></i>
                                    </p>
                                </a>
                                {openDropdown === 'bsetting' && (
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item"> <a href="#" className="nav-link active"> <i className="nav-icon bi bi-circle"></i>
                                            <p>home Page Setting</p>
                                        </a> </li>
                                    </ul>
                                )}
                            </li>

                        </ul>
                    </nav>
                </div>
            </aside>
            <main className="app-main">
            <div className="app-content-header"> 
                <div className="container-fluid"> 
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Dashboard</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-end">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Dashboard
                                </li>
                            </ol>
                        </div>
                    </div> 
                </div> 
            </div>
            <div className="app-content"> 
                <div className="container-fluid"> 
                    <div className="row"> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-primary">
                                <div className="inner">
                                    <h3>150</h3>
                                    <p>New Nursery Waiting List</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"></path>
                                </svg> <a href="#" className="small-box-footer link-light link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-success">
                                <div className="inner">
                                    <h3>53<sup className="fs-5"></sup></h3>
                                    <p>FeedBack</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                                </svg> <a href="#" className="small-box-footer link-light link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-warning">
                                <div className="inner">
                                    <h3>44</h3>
                                    <p>Nursery Registrations</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"></path>
                                </svg> <a href="#" className="small-box-footer link-dark link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-danger">
                                <div className="inner">
                                    <h3>65</h3>
                                    <p>Total Visitors</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"></path>
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"></path>
                                </svg> <a href="#" className="small-box-footer link-light link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                    </div> 
                   
                    <div className="row"> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-primary">
                                <div className="inner">
                                    <h3>150</h3>
                                    <p>Avalable Rating's</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"></path>
                                </svg> <a href="#" className="small-box-footer link-light link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                        <div className="col-lg-3 col-6"> 
                            <div className="small-box text-bg-success">
                                <div className="inner">
                                    <h3>31<sup className="fs-5"></sup></h3>
                                    <p>Support Tickets</p>
                                </div> <svg className="small-box-icon" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                                </svg> <a href="#" className="small-box-footer link-light link-underline-opacity-0 link-underline-opacity-50-hover">
                                    More info <i className="bi bi-link-45deg"></i> </a>
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div>
            </main>
         </div>
         
           
        </>
    )
}

export default Test
