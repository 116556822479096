import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AddNewNursery from './AddNewNursery';
import NurseryDetails from '../View/NurseryDetails';
import QR from '../../Assets/QR.png'


const NurseryBranchers = () => {
  const [activeComponent, setActiveComponent] = useState('AddNewNursery');

  const handleNavClick = (component) => {
    setActiveComponent(component);
  };
  return (
    <>
      <div className="row">

        <div className="col-xl-4">
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">Add New Nursery Branch</div>
            <div className="card-body text-center">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex align-items-center">
                  <Link className={`nav-link ${activeComponent === 'AddNewNursery' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('AddNewNursery')}>
                    <i className="bi bi-building-add mr-15"> Add New Nursey</i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">All Nursery's</div>
            <div className="card-body text-center">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex align-items-center ">
                  <Link className={`nav-link ${activeComponent === 'NurseryDetails' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('NurseryDetails')}>
                    <i className="ion ion-md-sunny mr-15"></i>Plantifo Nursery 01 <span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                  </Link>
                </li>
                <li className="list-group-item d-flex align-items-center ">
                  <Link className={`nav-link ${activeComponent === 'NurseryDetails' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('NurseryDetails')}>
                    <i className="ion ion-md-sunny mr-15"></i>Plantifo Nursery 02<span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <br />
          {activeComponent !== 'AddNewNursery' && (
            <div className="card mb-4 mb-xl-0">
              <div className="card-header">Nursery QR</div>
              <div className="card-body text-center">
                <img className="img-account-profile mb-2" src={QR} alt="QR Code" />
                <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                <button className="btn btn-success" type="button">Upload new logo</button>
              </div>
            </div>
          )}
        </div>

        {activeComponent === 'AddNewNursery' && <AddNewNursery />}
        {activeComponent === 'NurseryDetails' && <NurseryDetails />}
      </div>
    </>
  )
}
export default NurseryBranchers
