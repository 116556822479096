import React from 'react'
import avtar from '../../Assets/avtar.jpg'


const NurseryProfile = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Nursery Profile Picture</div>
                        <div className="card-body text-center">
                            <img className="img-account-profile rounded-circle mb-2" src={avtar} alt="" />
                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                            <button className="btn btn-success" type="button">Upload new logo</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card mb-4">
                        <div className="card-header">Nursery Details</div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Nursery Name</label>
                                        <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo Nursery" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Nursery Code</label>
                                        <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo-001" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName">First name (owner)</label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="Valerie" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Last name (owner)</label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="Luna" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName">Owner Email</label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="name@example.com" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Owner Phone Number</label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="555-123-4567" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputOrgName">Nursery Address</label>
                                        <input className="form-control" id="inputOrgName" type="text" placeholder="Enter your organization name" value="Start Bootstrap" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputLocation">City</label>
                                        <input className="form-control" id="inputLocation" type="text" placeholder="Enter your location" value="San Francisco, CA" />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputEmailAddress">Nursery Email address</label>
                                        <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="name@example.com" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputEmailAddress">Nursery Area Pin Code</label>
                                        <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value="101585" />
                                    </div>
                                </div>


                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryPhone">Nursery Phone number</label>
                                        <input className="form-control" id="nurseryPhone" type="tel" placeholder="Enter your phone number" value="555-123-4567" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Nursery Start Date</label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryPhone">Nursery Google Map Location</label>
                                        <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="https://maps.app.goo.gl/HR9VWb8WSVRW5z3f8" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Nursery Status</label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="In-Active" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryPhone">Nursery Size </label>
                                        <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="small" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Platfrom Reference</label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="Kunal" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryPhone">Create At  </label>
                                        <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="06/10/1988" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Last Updated At </label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
                                    </div>
                                </div>
                                <button className="btn btn-success" type="button">Save changes</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NurseryProfile
