import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Container1 from '../container/Container';
import Register from '../Register/Register';
import View from '../View/View';
import logo from '../../Assets/logo.png'
import Nursery from '../Nursery/Nursery';
import Admin from '../Admin/Admin';
const Dashboard = () => {
    const [activeComponent, setActiveComponent] = useState('dashboard');

    const handleNavClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand as={Link} to='/admin'> <img src={logo} width={100} height={35} alt="" /> Admin Dashboard</Navbar.Brand>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col md={3} lg={2} className="bg-success sidebar">
                        <div className="sidebar-sticky pt-3">
                            <Nav className="flex-column">
                                <Nav.Link as={Link} to="#" className={activeComponent === 'dashboard' ? 'active text-light' : 'text-light'} onClick={() => handleNavClick('dashboard')}>
                                    <i className="bi bi-house-door-fill"></i> Dashboard
                                </Nav.Link>
                                <NavDropdown title={<span className="text-light"><i className="bi bi-tree-fill"></i> Nursery</span>} id="nurseryDropdown" className="text-light">
                                    <NavDropdown.Item as={Link} to="#" onClick={() => handleNavClick('register')} className='text-dark'>Add New Nursery</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="#" onClick={() => handleNavClick('view')} className='text-dark'>View All Nursery</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="#" onClick={() => handleNavClick('nursery')} className='text-dark'> Nursery</NavDropdown.Item> */}
                                </NavDropdown>
                                <Nav.Link as={Link} className='text-light' to="#" onClick={() => handleNavClick('Admin')}>
                                    <i className="bi bi-person-gear"></i> Admin
                                </Nav.Link>
                            </Nav>
                        </div>
                    </Col>
                    <Col md={9} lg={10} className="px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Dashboard</h1>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="btn-group mr-2">
                                    <Button variant="outline-secondary" size="sm">
                                        <i className="bi bi-person-check"></i> Saurabh
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Row>
                            {activeComponent === 'dashboard' && <Container1 />}
                            {activeComponent === 'register' && <Register />}
                            {activeComponent === 'view' && <View />}
                            {activeComponent === 'nursery' && <Nursery />}
                            {activeComponent === 'Admin' && <Admin />}
                            {/* Add more conditional renderings for other components if needed */}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dashboard;
