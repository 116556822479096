import React from 'react'

const OpenTicket = () => {
  return (
    <>
      <div className="col-xl-8">
        <div className="card mb-4">
          <div className="card-header">Open Ticket</div>
          <div className="card-body">
            <form>
              <div className="row gx-3 mb-3">
                <div className="col-md-6">
                  <label className="small mb-1" for="inputUsername">Nursery Name</label>
                  <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo Nursery" />
                </div>
                <div className="col-md-6">
                  <label className="small mb-1" for="inputUsername">Nursery Code</label>
                  <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo-001" />
                </div>
              </div>
              <div className="row gx-3 mb-3">

                <div className="col-md-6">
                  <label className="small mb-1" for="firstName">First name (owner)</label>
                  <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="Valerie" />
                </div>

                <div className="col-md-6">
                  <label className="small mb-1" for="lastName">Last name (owner)</label>
                  <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="Luna" />
                </div>
              </div>
              <div className="row gx-3 mb-3">
                <div className="col-md-6">
                  <label className="small mb-1" for="firstName">Owner Email</label>
                  <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="name@example.com" />
                </div>
                <div className="col-md-6">
                  <label className="small mb-1" for="lastName">Owner Phone Number</label>
                  <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="555-123-4567" />
                </div>
              </div>
              <div className="row gx-3 mb-3">

                <div className="col-md-6">
                  <label className="small mb-1" for="inputOrgName">Subject</label>
                  <input className="form-control" id="inputOrgName" type="text" placeholder="Enter your organization name" value="Start Bootstrap" />
                </div>
                <div className="col-md-6">
                  <label className="small mb-1" for="inputLocation">Priority</label>
                  <input className="form-control" id="inputLocation" type="text" placeholder="Enter your location" value="High" />
                </div>
              </div>

              <div className="row gx-3 mb-3">
                <div className="col-md-12">
                  <label className="small mb-1" for="inputUsername"> Messages</label>
                  <textarea id="inputUsername" className="form-control" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." cols="30" rows="10"></textarea>
                </div>
              </div>


              <div className="row gx-3 mb-3">

                <div className="col-md-12">
                  <label className="small mb-1" for="nurseryPhone">Attachement</label>
                  <input className="form-control" id="nurseryPhone" type="file" />
                </div>
              </div>
              <button className="btn btn-success" type="button">Save changes</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenTicket
