import React from 'react'

const NurseryChangePassword = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Add New Admin</div>
                        <div className="card-body text-center">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex align-items-center">
                                    <a> <i className=" bi bi-arrow-repeat mr-15"></i> Change Password</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
                <div className="col-xl-8">
                    <div className="card mb-4">
                        <div className="card-header">Admin Details</div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputUsername">Old Password</label>
                                        <input className="form-control" id="inputUsername" type="password" placeholder="Enter your username" value="Plantifo Nursery" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputUsername">New Password</label>
                                        <input className="form-control" id="inputUsername" type="password" placeholder="Enter your username" value="Plantifo-001" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputUsername">Conform Password</label>
                                        <input className="form-control" id="inputUsername" type="password" placeholder="Enter your username" value="Plantifo-001" />
                                    </div>
                                </div>

                                <button className="btn btn-success" type="button">Save changes</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


export default NurseryChangePassword
