import React, { useState } from 'react'
import AdminProfile from './AdminProfile';
import { Link } from 'react-router-dom';
import AddNewAdmin from './AddNewAdmin';
import ChangePassword from './ChangePassword';


const Admin = () => {
    const [activeComponent, setActiveComponent] = useState('Profile');

    const handleNavClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div>
            <div className="container-xl px-4 mt-4">
                <nav className="nav nav-borders">
                    <Link
                        className={`nav-link ${activeComponent === 'Profile' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('Profile')}
                    >
                        Admin Profile
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'NewAdmin' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('NewAdmin')}
                    >
                        Add New Admin
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'ChangePassword' ? 'active ' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('ChangePassword')}
                    >
                        Change Password
                    </Link>
                    
                </nav>
                <hr className="mt-0 mb-4" />
                {activeComponent === 'Profile' && <AdminProfile />}
                {activeComponent === 'NewAdmin' && <AddNewAdmin />}
                {activeComponent === 'ChangePassword' && <ChangePassword />}
            </div>
        </div>
    );
}


export default Admin
