import React from 'react'



const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()
    return (
        <footer class="app-footer bg-dark" data-bs-theme="dark"> 
        <div class="float-end d-none d-sm-inline">Anything you want</div>  <strong>
            Copyright &copy;2022 - {year}&nbsp;
            <a href="https://mindscapetechnologies" class="text-decoration-none">MindScape Technologies</a>.
        </strong>
        
    </footer> 
    )
}

export default Footer
