import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TicketDashborad from '../View/TicketDashborad';
import OpenTicket from '../View/OpenTicket';
import Announcements from '../View/Announcements';
import RecentTicket from './../View/RecentTicket';

const NurserySupport = () => {
    const [activeComponent, setActiveComponent] = useState('TicketDashbord');

    const handleNavClick = (component) => {
        setActiveComponent(component);
    };
    return (
        <>
            <div className="row">

                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Nursery Support Section</div>
                        <div className="card-body text-center">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex align-items-center">
                                    <Link className={`nav-link ${activeComponent === 'TicketDashbord' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('TicketDashbord')}>
                                        <i className="bi bi-ticket-perforated mr-15"> My Support Tickets</i>
                                    </Link>

                                </li>
                                <li className="list-group-item d-flex align-items-center">
                                    <Link className={`nav-link ${activeComponent === 'OpenTicket' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('OpenTicket')}>
                                        <i className="bi bi-ticket-fill mr-15"> New Open Ticket</i>
                                    </Link>
                                </li>
                                <li className="list-group-item d-flex align-items-center">
                                    <Link className={`nav-link ${activeComponent === 'Announcements' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('Announcements')}>
                                        <i className="bi bi-megaphone mr-15"> Announcements</i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Your Recent Support Tickets</div>
                        <div className="card-body text-center">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex align-items-center ">
                                    <Link className={`nav-link ${activeComponent === 'RecentTicket' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('RecentTicket')}>
                                        <i className="ion ion-md-sunny mr-15"></i>#Pf-4321 Qr Not Working <span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                                    </Link>
                                </li>
                                <li className="list-group-item d-flex align-items-center ">
                                    <Link className={`nav-link ${activeComponent === 'RecentTicket' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('RecentTicket')}>
                                        <i className="ion ion-md-sunny mr-15"></i>#Pf-4321 Qr Not Working <span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                                    </Link>
                                </li>
                                <li className="list-group-item d-flex align-items-center ">
                                    <Link className={`nav-link ${activeComponent === 'RecentTicket' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('RecentTicket')}>
                                        <i className="ion ion-md-sunny mr-15"></i>#Pf-4321 Qr Not Working <span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                                    </Link>
                                </li>
                                <li className="list-group-item d-flex align-items-center ">
                                    <Link className={`nav-link ${activeComponent === 'RecentTicket' ? 'active' : ''}`}
                                        to="#"
                                        onClick={() => handleNavClick('RecentTicket')}>
                                        <i className="ion ion-md-sunny mr-15"></i>#Pf-4321 Qr Not Working <span style={{ color: "#198754" }} className="badge badge-Success text-sucesss badge-pill ml-15">06/10/1988</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {activeComponent === 'TicketDashbord' && <TicketDashborad />}
                {activeComponent === 'OpenTicket' && <OpenTicket />}
                {activeComponent === 'Announcements' && <Announcements />}
                {activeComponent === 'RecentTicket' && <RecentTicket />}
            </div>
        </>
    )
}

export default NurserySupport
