import React from 'react'

const RecentTicket = () => {
  return (



    <div className="col-xl-8">
      <div className="card mb-4">
        <div className="card-header">#Pf-4321 Qr Not Working </div>
        <div className="card-body">
          <div className="row gx-3 mb-3">
            <div className="col-md-12">
              <b className="small mb-1"> Plantifo team Message</b>
              <p id="inputUsername">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
          </div>
          <div className="row gx-3 mb-3">

            <div className="col-md-6">
              <label className="small mb-1" for="nurseryPhone">Status </label>
              <input className="form-control" id="nurseryPhone" type="text" placeholder="" value="Close" />
            </div>

            <div className="col-md-6">
              <label className="small mb-1" for="nurseryDate">Support By </label>
              <input className="form-control" id="nurseryDate" type="text" name="nurseryDate"  value="Plantifo Team" />
            </div>
          </div>
          <div className="row gx-3 mb-3">

            <div className="col-md-6">
              <label className="small mb-1" for="nurseryPhone">Create At  </label>
              <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="06/10/1988" />
            </div>

            <div className="col-md-6">
              <label className="small mb-1" for="nurseryDate">Last Updated At </label>
              <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentTicket
