import React from 'react'
import avtar from '../../Assets/avtar.jpg'


const AdminProfile = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Admin Profile Picture</div>
                        <div className="card-body text-center">
                            <img className="img-account-profile rounded-circle mb-2" src={avtar} alt="" />
                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                            <button className="btn btn-success" type="button">Upload new logo</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card mb-4">
                        <div className="card-header">Admin Details</div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Admin User Name</label>
                                        <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo Nursery" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Admin Code</label>
                                        <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo-001" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName">First name </label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="Valerie" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Last name </label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="Luna" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName"> Email</label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="name@example.com" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Phone Number</label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="555-123-4567" />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Nursery Status</label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="Active" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Role </label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="Admin" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryPhone">Create At  </label>
                                        <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="06/10/1988" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Last Updated At </label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
                                    </div>
                                </div>
                                <button className="btn btn-success" type="button">Save changes</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminProfile
