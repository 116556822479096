import React, { useState } from 'react';
import './style.css';
import avtar from '../../Assets/avtar.jpg';
import { Link } from 'react-router-dom';
import Nursery from './../Nursery/Nursery';
import Register from '../Register/Register';
import { Button } from 'react-bootstrap';

const View = () => {
    const [activeComponent, setActiveComponent] = useState('view');

    const handleNavClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <>
            {activeComponent === 'view' && (
                <div className="container">
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title ">
                                <div className="row">
                                    <div className="col-xs-5">
                                        <h2>Nursery <b>Management</b></h2>
                                    </div>
                                    <div className="col-xs-7">

                                        <Link onClick={() => handleNavClick('register')} className="btn btn-success"><i className="material-icons">&#xE147;</i> <span>Add New User</span></Link>
                                        <button className="btn btn-success"><i className="material-icons">&#xE24D;</i> <span>Export to Excel</span></button>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Date Created</th>
                                        <th>City</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><a href="#"><img src={avtar} width={100} className="avatar" alt="Avatar" /> Michael Holz</a></td>
                                        <td>04/10/2013</td>
                                        <td>Pune</td>
                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>
                                            <Link className="settings" title="Settings" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE8B8;</i></Link>
                                            <Link className="delete" title="Delete" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE5C9;</i></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td><a href="#"><img src={avtar} width={100} className="avatar" alt="Avatar" /> Paula Wilson</a></td>
                                        <td>05/08/2014</td>
                                        <td>Pune</td>
                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>
                                            <Link className="settings" title="Settings" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE8B8;</i></Link>
                                            <Link className="delete" title="Delete" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE5C9;</i></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td><a href="#"><img src={avtar} width={100} className="avatar" alt="Avatar" /> Antonio Moreno</a></td>
                                        <td>11/05/2015</td>
                                        <td>Pune</td>
                                        <td><span className="status text-danger">&bull;</span> Suspended</td>
                                        <td>
                                            <Link className="settings" title="Settings" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE8B8;</i></Link>
                                            <Link className="delete" title="Delete" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE5C9;</i></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td><a href="#"><img src={avtar} width={100} className="avatar" alt="Avatar" /> Mary Saveley</a></td>
                                        <td>06/09/2016</td>
                                        <td>Pune</td>
                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>
                                            <Link className="settings" title="Settings" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE8B8;</i></Link>
                                            <Link className="delete" title="Delete" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE5C9;</i></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td><a href="#"><img src={avtar} width={100} className="avatar" alt="Avatar" /> Martin Sommer</a></td>
                                        <td>12/08/2017</td>
                                        <td>Pune</td>
                                        <td><span className="status text-warning">&bull;</span> Inactive</td>
                                        <td>
                                            <Link className="settings" title="Settings" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE8B8;</i></Link>
                                            <Link className="delete" title="Delete" data-toggle="tooltip" onClick={() => handleNavClick('Profile')}><i className="material-icons">&#xE5C9;</i></Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix">
                                <div className="hint-text">Showing <b>5</b> out of <b>25</b> entries</div>
                                <ul className="pagination">
                                    <li className="page-item disabled"><a href="#">Previous</a></li>
                                    <li className="page-item"><a href="#" className="page-link">1</a></li>
                                    <li className="page-item"><a href="#" className="page-link">2</a></li>
                                    <li className="page-item active"><a href="#" className="page-link">3</a></li>
                                    <li className="page-item"><a href="#" className="page-link">4</a></li>
                                    <li className="page-item"><a href="#" className="page-link">5</a></li>
                                    <li className="page-item"><a href="#" className="page-link">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activeComponent === 'Profile' && <Nursery />}
            {activeComponent === 'register' && <Register />}
        </>
    );
};

export default View;
