import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Admin from './components/Dashboard/Dashboard';
import Nursery from './components/Nursery/Nursery';
import Test from './components/Test/Test';

function App() {
  return (
    <BrowserRouter>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <header>
          <Routes>
            <Route path='/' element={<Navbar />} />
            <Route path='/login' element={<Navbar />} />
            <Route path='/register' element={<Navbar />} />
            <Route path='/admin' element={null} />
            <Route path='/view' element={<Navbar />} />
  

          </Routes>
        </header>
        <main style={{ flex: '1' }}>
          <Routes>
            <Route path='/' element={<div>Home</div>} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/view' element={<Nursery />} />
            <Route path='/test' element={<Test />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
