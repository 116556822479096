import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddAdmin from './AddAdmin';
import AdminDetails from './AdminDetails';
import avtar from '../../Assets/avtar.jpg';

const AddNewAdmin = () => {
  const [activeComponent, setActiveComponent] = useState('AddNewAdmin');

  const handleNavClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">Add New Admin</div>
            <div className="card-body text-center">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex align-items-center">
                  <Link
                    className={`nav-link ${activeComponent === 'AddNewAdmin' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('AddNewAdmin')}
                  >
                    <i className="bi bi-building-add mr-15"></i> Add New Nursery
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">All Team</div>
            <div className="card-body text-center">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex align-items-center">
                  <Link
                    className={`nav-link ${activeComponent === 'AdminDetails' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('AdminDetails')}
                  >
                    <i className="ion ion-md-sunny mr-15"></i>Admin 01
                  </Link>
                </li>
                <li className="list-group-item d-flex align-items-center">
                  <Link
                    className={`nav-link ${activeComponent === 'AdminDetails' ? 'active' : ''}`}
                    to="#"
                    onClick={() => handleNavClick('AdminDetails')}
                  >
                    <i className="ion ion-md-sunny mr-15"></i>Admin 02
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <br />
          {activeComponent !== 'AddNewAdmin' && (
            <div className="card mb-4 mb-xl-0">
              <div className="card-header">Admin Profile</div>
              <div className="card-body text-center">
                <img className="img-account-profile mb-2" src={avtar} alt="Admin Profile" />
                <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                <button className="btn btn-success" type="button">Upload new Profile</button>
              </div>
            </div>
          )}
        </div>

        <div className="col-xl-8">
          {activeComponent === 'AddNewAdmin' && <AddAdmin />}
          {activeComponent === 'AdminDetails' && <AdminDetails />}
        </div>
      </div>
    </>
  );
};

export default AddNewAdmin;
