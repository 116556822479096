import React, { useState } from 'react';
import './style.css';
import NurseryProfile from './NurseryProfile';
import { Link } from 'react-router-dom';
import NurseryQr from './NurseryQr';
import NurseryBranchers from './NurseryBranchers';
import NurserySupport from './NurserySupport';
import NurseryOther from './NurseryOther';
import NurseryChangePassword from './NurseryChangePassword';

const Nursery = () => {
    const [activeComponent, setActiveComponent] = useState('Profile');

    const handleNavClick = (component) => {
        setActiveComponent(component);
    };

    return (
        <div>
            <div className="container-xl px-4 mt-4">
                <nav className="nav nav-borders">
                    <Link
                        className={`nav-link ${activeComponent === 'Profile' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('Profile')}
                    >
                        Nursery Profile
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'QR' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('QR')}
                    >
                        Nursery QR
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'Branchers' ? 'active ' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('Branchers')}
                    >
                        Nursery Branchers
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'Support' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('Support')}
                    >
                        Support
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'Other' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('Other')}
                    >
                        Other
                    </Link>
                    <Link
                        className={`nav-link ${activeComponent === 'ChangePassword' ? 'active' : ''}`}
                        to="#"
                        onClick={() => handleNavClick('ChangePassword')}
                    >
                        Change Password
                    </Link>
                </nav>
                <hr className="mt-0 mb-4" />
                {activeComponent === 'Profile' && <NurseryProfile />}
                {activeComponent === 'QR' && <NurseryQr />}
                {activeComponent === 'Branchers' && <NurseryBranchers />}
                {activeComponent === 'Support' && <NurserySupport />}
                {activeComponent === 'Other' && <NurseryOther />}
                {activeComponent === 'ChangePassword' && <NurseryChangePassword />}
            </div>
        </div>
    );
}

export default Nursery;
