import React from 'react'

const AddAdmin = () => {
    return (
        <>
                    <div className="card mb-4">
                        <div className="card-header">Admin Details</div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Admin User Name</label>
                                        <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" value="Plantifo Nursery" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="inputUsername">Admin Password</label>
                                        <input className="form-control" id="inputUsername" type="password" placeholder="Enter your username" value="Plantifo-001" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName">First name </label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="Valerie" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Last name </label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="Luna" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="firstName"> Email</label>
                                        <input className="form-control" id="firstName" type="text" placeholder="Enter your first name" value="name@example.com" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" for="lastName">Phone Number</label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="555-123-4567" />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Nursery Status</label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="Active" />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="small mb-1" for="nurseryDate">Role </label>
                                        <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="Admin" />
                                    </div>
                                </div>
                                <div className="row gx-3 mb-3">

                                    <div className="col-md-12">
                                        <label className="small mb-1" for="nurseryPhone">Admin Profile Picture   </label>
                                        <input className="form-control" id="nurseryPhone" type="file" />
                                    </div>
                                </div>
                                <button className="btn btn-success" type="button">Save changes</button>
                            </form>
                        </div>
                    </div>
        </>
    )
}


export default AddAdmin
