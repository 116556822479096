import React from 'react'

const TicketDashborad = () => {
    return (
        <>
            <div className="col-xl-8">
                <div className="card mb-4">
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title ">
                                <div className="row">
                                    <div className="col-xs-5">
                                        <h2>Tickets</h2>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Subject</th>
                                        <th>Status</th>
                                        <th>last Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><a href="#"> #Pf-4321 Qr Not Working</a></td>
                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>06/10/1988</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td><a href="#">#Pf-4321 Qr Not Working</a></td>

                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>06/10/1988</td>


                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td><a href="#">#Pf-4321 Qr Not Working</a></td>

                                        <td><span className="status text-danger">&bull;</span> Close</td>
                                        <td>06/10/1988</td>


                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td><a href="#"> #Pf-4321 Qr Not Working</a></td>

                                        <td><span className="status text-success">&bull;</span> Active</td>
                                        <td>06/10/1988</td>


                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td><a href="#"> #Pf-4321 Qr Not Working</a></td>

                                        <td><span className="status text-warning">&bull;</span> Progress</td>
                                        <td>06/10/1988</td>


                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix">
                                <div className="hint-text">Showing <b>5</b> out of <b>25</b> entries</div>
                                <ul className="pagination">
                                    <li className="page-item disabled"><a href="#">Previous</a></li>
                                    <li className="page-item"><a href="#" className="page-link">1</a></li>
                                    <li className="page-item"><a href="#" className="page-link">2</a></li>
                                    <li className="page-item active"><a href="#" className="page-link">3</a></li>
                                    <li className="page-item"><a href="#" className="page-link">4</a></li>
                                    <li className="page-item"><a href="#" className="page-link">5</a></li>
                                    <li className="page-item"><a href="#" className="page-link">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketDashborad
