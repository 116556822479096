import React from 'react'
import {Col, Card } from 'react-bootstrap';


const Container1 = () => {
    return (
        <div className='row'>
            <Col md={4}>
                <Card className="mb-4 shadow-sm">
                    <Card.Header className="bg-success text-white">
                        <h4 className="my-0 font-weight-normal">Card Title 1</h4>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="mb-4 shadow-sm">
                    <Card.Header className="bg-success text-white">
                        <h4 className="my-0 font-weight-normal">Card Title 2</h4>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="mb-4 shadow-sm">
                    <Card.Header className="bg-success text-white">
                        <h4 className="my-0 font-weight-normal">Card Title 3</h4>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
}

export default Container1
