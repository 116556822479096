import React from 'react'
import avtar from '../../Assets/avtar.jpg'

const NurseryOther = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card mb-4 mb-xl-0">
                        <div className="card-header">Nursery Profile Picture</div>
                        <div className="card-body text-center">
                            <img className="img-account-profile rounded-circle mb-2" src={avtar} alt="" />
                            <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                            <button className="btn btn-success" type="button">Upload new logo</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card mb-4">
                        <div className="card-header">Nursery Other Details</div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="lastName"> Title</label>
                                        <input className="form-control" id="lastName" type="text" placeholder="Enter your last name" value="Lorem Ipsum" />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="small mb-1" for="inputUsername"> Other Details</label>
                                        <textarea id="inputUsername" className="form-control" value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." cols="30" rows="10"></textarea>
                                    </div>
                                </div>
                                <button className="btn btn-success" type="button">Save changes</button>
                            </form>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">Nursery Save Other Details</div>
                        <div className="card-body">
                            <div className="row gx-3 mb-3">
                                <div className="col-md-12">
                                    <b className="small mb-1"> Other Details</b>
                                    <p id="inputUsername">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                </div>
                            </div>
                            <div className="row gx-3 mb-3">

                                <div className="col-md-6">
                                    <label className="small mb-1" for="nurseryPhone">Create At  </label>
                                    <input className="form-control" id="nurseryPhone" type="text" placeholder="Enter your phone number" value="06/10/1988" />
                                </div>

                                <div className="col-md-6">
                                    <label className="small mb-1" for="nurseryDate">Last Updated At </label>
                                    <input className="form-control" id="nurseryDate" type="text" name="nurseryDate" placeholder="Enter nursery start date" value="06/10/1988" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NurseryOther
